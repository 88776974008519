import React, { Component } from "react";
import { Container, Segment, Divider, Header, Image } from "semantic-ui-react";
import luckoryLogo from "../imgs/logo7.png";

class TermsOfServicePage extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          backgroundColor: "white",
        }}
      >
        {/* ------------------------------  header panel ----------------------------- */}
        <Segment
          style={{
            height: "50px",
            top: "-1px",
            width: "100%",
            position: "fixed",
            padding: "5px",
            margin: "0",
            zIndex: "10",
          }}
        >
          <Image
            src={luckoryLogo}
            size="small"
            style={{
              margin: "0px auto",
              display: "inline-block",
              left: "calc(50% - 68px)",
              verticalAlign: "0",
              position: "absolute",
              height: "25px",
              width: "auto",
              top: "12px",
            }}
            // className="hide-if-medium noselect"
            className="noselect"
          />
        </Segment>

        <Container>
          <Segment textAlign="left" basic style={{ top: "50px" }}>
            <Header as="h2" textAlign="center">
              Terms and Conditions Agreement
            </Header>
            <Header as="h5">Effective: May 28, 2020</Header>
            <p>
              Thank you for choice Luckory Before using our services, please
              read the terms and conditions carefully. The terms and conditions
              (“agreement”) constitute a legal agreement between you and Luckory
              (“Luckory,” “we,” “us,” or “our”). Luckory provides its service to
              you through its web site or restaurant sites (collectively, the
              “site”), these terms and conditions constitute a legal agreement
              between you and us regarding your use of the site. By using our
              site, you acknowledge that you have read and understood these
              terms. You represent and warrant that: you are over 13 years old;
              and if you are between the ages of 13 years old and 18 years old,
              please obtain your parent or guardian’s permission to use the site
              and review the terms of use with your parent or guardian. If you
              do not agree to these terms of use, please do not use the site.
            </p>
            <Header as="h3">Acceptance of this Agreement</Header>
            <p>
              The Luckory provides an online ordering connection, using
              web-based technology although you place orders through our site
              that connects you and other consumers, restaurants and/or other
              businesses. The site permits consumers to place orders for food
              and/or other goods from various restaurants and businesses. We
              collect certain information from you when you place an order
              through the site, including, but not limited to, your name,
              address, phone number, email address, or payment information. By
              placing an order through the site, you consent to us collecting
              your personal information and keeping it for as long as we deem
              necessary. You agree that the information you provide is true,
              accurate, and up-to-date, including all payment information.
            </p>
            <Header as="h3">Modifications</Header>
            <p>
              Subject to this Agreement, Luckory reserves the right to modify
              the terms and conditions of this Agreement or its policies
              relating to the Services at any time, effective upon posting of an
              updated version of this Agreement through the Services. You should
              regularly review this Agreement, as your continued use of the
              Services after any such changes constitutes your agreement to such
              changes.
            </p>
            <Header as="h3">Rules and Prohibitions</Header>
            <p>
              By using the Services, you agree that: 1. You will only use the
              Services for lawful purposes; you will not use the Services for
              sending or storing any unlawful material or for deceptive or
              fraudulent purposes; and You will not harass, annoy, intimidate,
              or threaten any of our employees or agents in connection with
              using the Site or services provided; 2. You will not use any
              automated means, data scraping, extraction, or other systematic
              data retrieval from the Site; you will not make any unauthorized
              use of the Site; 3. You will not use the Services in any way that
              could damage, disable, overburden or otherwise interfere with the
              security of the Site; You will not interfere with, disrupt, or
              create an undue burden on the Site or the networks or services
              connected to the Site; 4. You will not scan or test the
              vulnerability of any network or circumvent any security Or
              authentication measures Luckory may use to prevent or restrict
              access to the Services or use of the Services or the content
              therein. 5. You will not deep-link to the Site or access the site
              manually or with any extraction software, automated process to
              scrape, copy, index, frame, or monitor any portion of the site or
              any content on the site. 6. You will not try to harm other Users
              or the Services in any way whatsoever. You will not make false or
              misleading statements about the Site or our services.
            </p>
            <Header as="h3">PRIVACY POLICY</Header>
            <p>
              Please review our Privacy Policy located at:
              https://www.Luckory.com/privacy. By using the Site, you agree to
              be bound by our Privacy Policy, which is incorporated into these
              Terms of Use.
            </p>
            <Header as="h3">Intellectual Property</Header>
            <p>
              Luckory shall own all right, title and interest, including all
              related intellectual property rights, in and to the Services.
              intellectual property rights owned by Luckory. The Site holds
              certain content, such as text, graphics, logos, button icons,
              images, data compilations, and software, that is the property of
              Luckory or its content suppliers and protected by international
              copyright laws. Luckory reserves the right to use Your company
              name, logo, content, trademarks and copyrights for the purpose of
              providing an online (Web), mobile and/or app experience. You agree
              that you will not remove, alter or obscure any copyright,
              trademark, service mark or other proprietary rights notices
              incorporated in or accompanying the Services.
            </p>
            <Header as="h3">Payments</Header>
            <p>
              You placed order(s) through the Site. You agree that all
              information you submit when you place an order will be true,
              accurate, current, and complete. If you provide any information
              that is untrue, inaccurate, not current, or incomplete, we have
              the right to suspend, terminate, or refuse current or future use
              of the Site. Luckory uses third-party Application Programming
              Interface (&quot;API&quot;) gateway to processing credit card and
              these API gateway may store, retain, or use your billing
              information to process Your credit card information. Luckory will
              make reasonable efforts to maintain the confidentiality of your
              personal information in its possession. Notwithstanding the
              foregoing, however, Luckory accepts no responsibility and will not
              be liable for any loss, damage, misuse, or unauthorized access of
              Your Sensitive Information even if Luckory has been advised of the
              possibility of such liability.
            </p>

            <Header as="h3" name="SMS_Terms_Conditions">
              SMS Text Messaging Terms & Conditions
            </Header>
            <p>
              Please read these SMS/Text Messaging Terms & Conditions carefully.
              By completing the SMS/Text Messaging Opt-In Form, you expressly
              consent to receive non-marketing and marketing text messages from
              Luckory Online Ordering "LUCKORY", including text messages made
              with an Auto dialer, at the mobile phone number(s) you
              provide.Message frequency varies. You may opt-out of these
              communications at any time by replying STOP to any text message
              from LUCKORY or by otherwise contacting the LUCKORY as indicated
              below.Text HELP to (+1)2542366668 for help. Consent to receive
              non-marketing or marketing text messages is not required to
              purchase any products or services from the LUCKORY. Messaging and
              data rates may apply. By completing the SMS text Messaging Opt-In
              Form, you also accept and agree to be bound by these SMS Text
              Messaging Terms & Conditions and any other applicable terms and
              agreements related to your use of LUCKORY services. Carriers are
              not liable for delayed or undelivered messages.
            </p>

            <Header as="h3">Disclaimer of Warranties</Header>
            <p>
              LUCKORY CANNOT GUARANTEE THE SITE WILL BE AVAILABLE AT ALL TIMES.
              AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN &quot;AS
              IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT ANY WARRANTIES
              OF ANY KIND, LUCKORY WILL NOT BE LIABLE TO YOU FOR DAMAGES
              RESULTING FROM THE FAILURE OF THE SITE. TO THE FULLEST EXTENT
              PERMITTED BY LAW AND OTHER THAN AS EXPRESSLY PROVIDED IN THE
              AGREEMENT, LUCKORY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
              IN CONNECTION WITH THE TERMS OF USE, YOU ACKNOWLEDGE THAT LUCKORY
              DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
              SECURE, ERROR-FREE OR VIRUS-FREE AND NO INFORMATION OR ADVICE
              OBTAINED. THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
              TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR
              ANY DAMAGES ARISING FROM YOUR USE OF THE SITE OR EXTERNAL SITES.
              THE SITE, AND ANY USE THEREOF, INCLUDING WITHOUT LIMITATION
              DISCLAIMS ANY AND ALL STATUTORY WARRANTIES, INCLUDING, WITHOUT
              LIMITATION, ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, FITNESS FOR
              A PARTICULAR PURPOSE, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED
              OPERATION AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING,
              COURSE OF PERFORMANCE OR USAGE OF TRADE. WE DO NOT WARRANT,
              ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
              SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE.
            </p>
            <Header as="h3">Limitation of Liability</Header>
            <p>
              LUCKORY WILL NOT BE LIABLE FOR LOST PROFITS, LOSS OF BUSINESS OR
              OTHER CONSEQUENTIAL, SPECIAL, INDIRECT, OR PUNITIVE DAMAGES, EVEN
              IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR ANY CLAIM BY
              ANY THIRD PARTY EXCEPT AS EXPRESSLY PROVIDED HEREIN. LUCKORY WILL
              NOT BE LIABLE FOR PRODUCTS OR SERVICES NOT BEING AVAILABLE FOR USE
              OR FOR LOST OR CORRUPTED DATA OR SOFTWARE. CUSTOMER AGREES THAT
              FOR ANY LIABILITY RELATED TO THE PURCHASE OF PRODUCTS OR SERVICES,
              LUCKORY IS NOT LIABLE OR RESPONSIBLE FOR ANY AMOUNT OF DAMAGES
              ABOVE THE AGGREGATE DOLLAR AMOUNT PAID BY CUSTOMER FOR THE
              PURCHASE(S) UNDER THIS AGREEMENT.
            </p>
            <Header as="h3">Cancellation and Termination</Header>
            <p>
              These Terms of Use shall remain in full force and effect while you
              use the Site. We reserve the right, Either You or Luckory
              terminates your account at any time, with or without cause, upon
              notice. including violation of these Terms of Use, fraudulent,
              criminal, or illegal behavior, or for no reason at all. You are
              responsible for properly canceling your account. The account owner
              can cancel the account upon settling all outstanding payment. You
              will lose access to all of your content, sites and services upon
              cancellation and we may delete or reuse them at any time. This
              information cannot be recovered for you once your account is
              canceled.
            </p>
            <Header as="h3">Governing Law</Header>
            <p>
              These Terms of Service and any dispute or claim arising out of, or
              related to them, shall be governed by and construed in accordance
              with the internal laws of the us without giving effect to any
              choice or conflict of law provision or rule. Any legal suit,
              action or proceeding arising out of, or related to, these Terms of
              Service or the Website shall be instituted exclusively in the
              federal courts of us.
            </p>
            <Header as="h3">Contact Information</Header>
            <p>
              Luckory welcomes your questions or comments regarding the Terms:
              <br />
              Luckory
              <br /> 3063 Peachtree Industrial Blvd STE 200 <br />
              Duluth, GA 30097
              <br />
              Phone Number: (678) 846-6666
            </p>
          </Segment>
        </Container>

        <Divider
          className="no-margin"
          style={{
            top: "50px",
            position: "relative",
          }}
        />
        <Segment
          basic
          style={{
            margin: "0",
            color: "#6f6f6f",
            fontSize: "11px",
            top: "50px",
          }}
        >
          <a
            href="/document/terms-of-service"
            style={{ margin: "0 10px", color: "#6f6f6f" }}
          >
            Terms of Service
          </a>

          <a
            href="/document/privacy-policy"
            style={{ margin: "0 10px", color: "#6f6f6f" }}
          >
            Privacy
          </a>
          <span style={{ margin: "0 10px" }}>© 2025 Luckory</span>
        </Segment>
      </div>
    );
  }
}

export default TermsOfServicePage;
