import React, { Component } from "react";
import Email_msmDb from "./../dataModel/email_msmDb";
import ScriptTag from "react-script-tag";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// import Carousel from "react-elastic-carousel";

class WelcomePanel extends Component {
  CONTACT_ROLE = {
    OWNER: 0,
    RESELLER: 1,
  };

  STEP = {
    ONE: 0,
    TWO: 1,
    THREE: 2,
  };

  state = {
    contact_role: 0, // owner by default
    storeName_valid: true,
    ownerName_valid: true,
    phone_valid: true,
    step: this.STEP.ONE,
  };

  componentDidMount = () => {};

  switchStepTo = (step) => {
    this.setState({
      step,
    });
  };

  handleContactRoleChange = (role) => {
    this.setState({
      contact_role: role,
    });
  };

  handleContactInfoSubmit = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    const isOwner = this.state.contact_role === this.CONTACT_ROLE.OWNER;

    const storeName =
      document.getElementById("storeName") &&
      document.getElementById("storeName").value;
    const ownerName = document.getElementById("ownerName").value;
    const phone = document.getElementById("phone").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;

    let storeName_valid = true;
    let ownerName_valid = true;
    let phone_valid = true;
    if (isOwner && (!storeName || storeName.trim() === "")) {
      storeName_valid = false;
    }
    if (!ownerName || ownerName.trim() === "") {
      ownerName_valid = false;
    }
    if (!phone || phone.trim() === "") {
      phone_valid = false;
    }

    if (!storeName_valid || !ownerName_valid || !phone_valid) {
      return this.setState({
        storeName_valid,
        ownerName_valid,
        phone_valid,
      });
    }

    const contactInfo = {
      storeName,
      ownerName,
      phone,
      email,
      message,
      isOwner,
    };
    await Email_msmDb.sendMerchantContactInfoTo(contactInfo, {
      recipients: ["zhengrs1011@gmail.com"],
    });

    window && (window.location.href = "/contact_submit");
  };

  render() {
    return (
      <div>
        <div id="body-welcomepage" className="landing is-preload">
          <div id="page-wrapper">
            {/* <!-- Header --> */}
            <header id="header" className="alt">
              <h1>
                <a href="index.html" style={{ fontSize: "16px" }}>
                  <img
                    src="images/logo7.png"
                    alt="Luckory"
                    style={{ height: "34px", margin: "9px 0" }}
                  />
                </a>
              </h1>
              <a href="#nav" style={{ color: "white" }}>
                <span></span>
              </a>
            </header>

            {/* <!-- Nav --> */}
            <nav id="nav">
              <ul className="actions stacked">
                <li>
                  <a
                    href="https://www.luckory.com/merchant/login"
                    className="button fit primary"
                  >
                    Merchant Sign In
                  </a>
                </li>
              </ul>
            </nav>

            {/* <!-- Banner --> */}
            <section id="banner">
              <div
                className="inner"
                style={{ padding: "25px calc(50vw - 400px)" }}
              >
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: "0",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/0IjfT6ldliA"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: "0",
                      left: "0",
                    }}
                  ></iframe>
                </div>

                <h2 style={{ color: "white" }}>
                  Online Ordering <br /> made for your restaurant
                </h2>
                <ul className="actions special">
                  <li>
                    <a
                      href="https://www.luckory.com/store/1987012000"
                      className="button scrolly"
                      style={{
                        backgroundColor: "#f1bc41",
                        color: "#211410",
                        fontSize: "17px",
                        fontWeight: "700",
                      }}
                    >
                      See a demo
                    </a>
                  </li>
                </ul>
              </div>
            </section>

            {/* <!-- One --> */}
            <section id="one" className="wrapper special">
              <div className="inner">
                <ul className="icons major">
                  <li
                    className={
                      this.state.step === this.STEP.ONE ? "special" : ""
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => this.switchStepTo(this.STEP.ONE)}
                  >
                    <span className="icon solid alt fa-paper-plane">
                      <span className="label">Send</span>
                    </span>
                  </li>
                  <li
                    className={
                      this.state.step === this.STEP.TWO ? "special" : ""
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => this.switchStepTo(this.STEP.TWO)}
                  >
                    <span className="icon solid alt fa-tv">
                      <span className="label">TV</span>
                    </span>
                  </li>
                  <li
                    className={
                      this.state.step === this.STEP.THREE ? "special" : ""
                    }
                    style={{ cursor: "pointer" }}
                    onClick={() => this.switchStepTo(this.STEP.THREE)}
                  >
                    <span className="icon solid alt fa-glass-cheers">
                      <span className="label">Ready</span>
                    </span>
                  </li>
                </ul>
                {this.state.step === this.STEP.ONE ? (
                  <header>
                    <h2 style={{ marginBottom: "35px" }}>Send us your menu</h2>
                    <p>
                      We'll set up your menu for online ordering & design a
                      unique webpage for your restaurant in just couple days.
                    </p>
                  </header>
                ) : this.state.step === this.STEP.TWO ? (
                  <header>
                    <h2 style={{ marginBottom: "35px" }}>
                      We Setup your Order management system
                    </h2>
                    <p>
                      Through our web-based order management system, you can
                      access to your coming orders anywhere, anytime as long as
                      you can access to the internet. We'll also setup your
                      printers to receive online orders, which means you don't
                      even need to notice, your chef is preparing the yummy food
                      for your customers.
                    </p>
                  </header>
                ) : (
                  <header>
                    <h2 style={{ marginBottom: "35px" }}>
                      Start Receiving online orders
                    </h2>
                    <p>
                      Now, your customers could take their time to check your
                      items online and place the orders easily.
                    </p>
                  </header>
                )}
              </div>
            </section>

            {/* <!-- Two --> */}
            <section id="two" className="wrapper style1">
              <div className="inner">
                <header className="special major narrow">
                  <small>Mobile & Desktop</small>
                  <h2>What your customers see</h2>
                  <p>
                    We focus on building a product for your customers to know
                    your items completely, order easily and pay securely.
                  </p>
                </header>
                <div className="features">
                  <ul className="left">
                    <li className="icon solid fa-dolly">
                      <span className="label">dolly</span>
                      Support interface for takeout, curbside pickup(eg. Car
                      info) and delivery(if your restautant supports).
                    </li>
                    <li className="icon solid fa-sms">
                      <span className="label">SMS</span>
                      Support order placed notification by Text Message.
                    </li>
                  </ul>

                  <Carousel showArrows={false}>
                    <div>
                      <img src="images/mobile1.png" style={{ width: "50%" }} />
                    </div>
                    <div>
                      <img src="images/mobile2.png" style={{ width: "50%" }} />
                    </div>
                    <div>
                      <img src="images/mobile3.png" style={{ width: "50%" }} />
                    </div>
                  </Carousel>
                  {/* <span className="image fit">
                    <img src="images/pic01.jpg" alt="" />
                  </span> */}
                  <ul className="left">
                    <li className="icon solid fa-lock">
                      <span className="label">Lock</span>
                      End to End transaction. Protect your customer's payment
                      security.
                    </li>
                    <li className="icon solid fa-tags">
                      <span className="label">tags</span>
                      Others like supporting coupon code, customer loyalty &
                      more
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* <!-- Three --> */}
            <section id="three" className="wrapper">
              <div className="inner spotlight left">
                {/* <span className="image fit">
                  <img src="images/pic02.jpg" alt="" />
                </span> */}
                <Carousel showArrows={false}>
                  <div>
                    <img src="images/homepage01.png" style={{ width: "85%" }} />
                  </div>
                  <div>
                    <img src="images/homepage02.png" style={{ width: "85%" }} />
                  </div>
                  <div>
                    <img src="images/homepage03.png" style={{ width: "85%" }} />
                  </div>
                </Carousel>

                <article style={{ minWidth: "230px" }}>
                  <header>
                    <small>Your Homepage</small>
                    <h2>The place to show off the foods you are proud of</h2>
                  </header>
                  <p>
                    We build the homepage unique for your restaurant and add
                    online ordering link into it.
                  </p>
                </article>
              </div>
            </section>

            {/* <!-- Four --> */}
            <section id="four" className="wrapper style3">
              <div className="inner spotlight right">
                <Carousel showArrows={false}>
                  <div style={{ maxWidth: "450px", margin: "0 auto" }}>
                    <img src="images/merchant01.png" />
                  </div>
                  <div style={{ maxWidth: "450px", margin: "0 auto" }}>
                    <img src="images/merchant02.png" />
                  </div>
                  <div style={{ maxWidth: "450px", margin: "0 auto" }}>
                    <img src="images/merchant03.png" />
                  </div>
                  <div style={{ maxWidth: "450px", margin: "0 auto" }}>
                    <img src="images/merchant04.png" />
                  </div>
                </Carousel>
                <article style={{ minWidth: "350px" }}>
                  <header>
                    <small>Mobile & Desktop</small>
                    <h2>Where you check online orders & more</h2>
                  </header>
                  <ul className="checklist">
                    <li>Instant order coming notification</li>
                    <li>Support adding tips, voiding orders & more</li>
                    <li>
                      Access to interested orders easily by date filter function
                    </li>
                    <li>Sales report by any range of dates</li>
                    <li>Modify your menu easily by just couple clicks</li>
                  </ul>
                </article>
              </div>
            </section>

            {/* <!-- Five --> */}
            <section id="five" className="wrapper special">
              <div className="inner">
                <header>
                  <small>Contact</small>
                  <h2 style={{ marginBottom: "20px" }}>
                    Let's do a quick demo for you
                  </h2>
                </header>
                <ul className="labeled-icons">
                  <li>
                    <span className="icon solid fa-mobile-alt">
                      718-685-9908
                    </span>
                  </li>
                  <li>
                    <span className="icon solid fa-envelope">
                      support@luckory.com
                    </span>
                  </li>
                  <li>
                    <span className="icon solid fa-map-marker-alt">
                      Atlanta, GA
                    </span>
                  </li>
                </ul>
                <form id="contact" action="" method="get">
                  <div className="row gtr-uniform gtr-50%">
                    <div className="col-6 col-12-xsmall">
                      <input
                        id="storeName"
                        name="storeName"
                        placeholder="Store Name"
                        type="text"
                      />
                    </div>
                    <div className="col-6 col-12-xsmall">
                      <input
                        id="ownerName"
                        name="YourName"
                        placeholder="Your Name"
                        type="email"
                      />
                    </div>
                    <div className="col-6 col-12-xsmall">
                      <input
                        id="phone"
                        name="phone"
                        placeholder="Phone Number"
                        type="text"
                      />
                    </div>
                    <div className="col-6 col-12-xsmall">
                      <input
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="email"
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Message (optional)"
                        rows="6"
                      ></textarea>
                    </div>
                  </div>
                  <ul className="actions special">
                    <li>
                      <input
                        type="submit"
                        value="Send message"
                        onClick={this.handleContactInfoSubmit}
                      />
                    </li>
                  </ul>
                </form>
              </div>
            </section>

            {/* <!-- Footer --> */}
            <footer id="footer">
              <div className="copyright">
                <span style={{ margin: "0 10px" }}>
                  Copyright &copy; 2025 Luckory
                </span>
                <a
                  href="/document/terms-of-service"
                  style={{ margin: "0 10px", color: "white" }}
                >
                  Terms of Service
                </a>
                <a
                  href="/document/privacy-policy"
                  style={{ margin: "0 10px", color: "white" }}
                >
                  Privacy
                </a>
              </div>
            </footer>
          </div>

          {/* <!-- Scripts --> */}
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/breakpoints.min.js"
          />
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/browser.min.js"
          />
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/jquery.min.js"
          />
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/jquery.scrollex.min.js"
          />
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/jquery.scrolly.min.js"
          />
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/util.js"
          />
          <ScriptTag
            // isHydrating={true}
            type="text/javascript"
            src="assets/js/main.js"
          />
        </div>
      </div>
    );
  }
}

export default WelcomePanel;
